import React from "react"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

const query = graphql`
  {
    site {
      siteMetadata {
        author
        siteDescription: description
        image
        siteUrl
        siteTitle: title
        twitterUsername
      }
    }
  }
`

const SEO = ({title, description}) => {
  const {site} = useStaticQuery(query)
  const {siteDescription, siteTitle, siteUrl, image, twitterUsername} = site.siteMetadata
  return (
    <Helmet htmlAttributes={{lang:"en"}} title={`${title} | ${siteTitle}`}>
      <meta name='description' content={description || siteDescription} />
      <meta name='image' content={image} />
      <meta name='twitter:card' content='summary_large_image' />
      <meta name='twitter:creator' content={twitterUsername} />
      <meta name='twitter:title' content={siteTitle} />
      <meta name='twitter:description' content={siteDescription} />
      <meta name='twitter:image' content={`${siteUrl}${image}`} />
      <script src="https://embed.small.chat/T01CJNB4B8QG01BN7J1WK0.js" async></script>
    </Helmet>
  )
}

export default SEO
