import React from "react"
import SocialLinks from "../constants/socialLinks"
const Footer = () => {
  return (
    <footer className='footer'>
      <div>
        <SocialLinks styleClass="footer-links text-center" />
        <h4>
          &copy;
          <span>Lancaster Digital</span>
        </h4>
        <h4>Website by <a href='https://wolfonmoon.com'>Wolf on Moon</a></h4>
      </div>
    </footer>
  )
}

export default Footer
